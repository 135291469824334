import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { minValueRule, requiredRule } from '@/vue-app/utils/form-rules';

// Application
import EditRetirementFundGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-current-plan-service';
import EditRetirementFundGoalPlanCurrentAmountService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-current-amount-service';

// Domain
import { CurrentAmountEntity }
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/domain/entities/current-amount-entity';
import { CurrentPlanEntity }
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/domain/entities/current-plan-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class EditRetirementFundYourCurrentAmountViewModel {
  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditRetirementFundGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_AMOUNT_SERVICE)
  private readonly current_amount_service!: EditRetirementFundGoalPlanCurrentAmountService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-retirement-fund-goal-plan.edit_retirement_fund_current_amount';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  current_plan_information: CurrentPlanEntity = {
    target_amount: '',
    current_amount: '',
    monthly_required_amount: '',
    retirement_age: '',
    monthly_pension: '',
    associated_product_id: '',
    retirement_fund_id: '',
  }

  progress = '0';

  there_is_more_savings = 'no';

  additional_amount = '';

  amount_added = '';

  is_valid_form = false;

  input_rules = {
    additional_amount: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$1.00 MXN')],
    amount_added: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$0.00 MXN', 0)],
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  there_is_more_savings_options = [
    {
      label: this.translate('yes'),
      value: 'yes',
    },
    {
      label: this.translate('no'),
      value: 'no',
    },
  ];

  initialize = () => {
    this.setInitialValues();
  }

  get show_additional_amount() {
    return this.there_is_more_savings === 'yes';
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form || (this.parseCurrencyToNumber(this
      .additional_amount) < this.parseCurrencyToNumber(this.amount_added));
  }

  get amount_added_formatted() {
    return this.getAmountFormatted(this.parseCurrencyToNumber(this.amount_added));
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getCurrentPlanInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  getCurrentAmountInformation = () => (this.current_amount_service.getCurrentAmountInformation());

  setCurrentAmountInformation = (current_amount: CurrentAmountEntity) => {
    this.current_amount_service.setCurrentAmountInformation(current_amount);
  }

  setInitialValues = () => {
    const current_plan = this.getCurrentPlanInformation();
    const current_amount = this.getCurrentAmountInformation();
    this.current_plan_information = { ...current_plan };
    this.progress = ((this.parseCurrencyToNumber(this.current_plan_information
      .current_amount) * 100) / this.parseCurrencyToNumber(this
      .current_plan_information.target_amount)).toFixed(2);
    if (current_amount.additional_amount > 0) {
      this.additional_amount = String(current_amount.additional_amount);
      this.amount_added = String(current_amount.amount_added);
      this.there_is_more_savings = current_amount.there_is_more_savings;
    }
  }

  parseCurrencyToNumber = (currency: string) => parseFloat(currency.replace(/[^0-9.]/g, ''));

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    const current_amount: CurrentAmountEntity = {
      // eslint-disable-next-line max-len
      additional_amount: (this.additional_amount) ? this.parseCurrencyToNumber(this.additional_amount) : 0,
      there_is_more_savings: this.there_is_more_savings,
      amount_added: (this.amount_added) ? this.parseCurrencyToNumber(this.amount_added) : 0,
    };
    this.setCurrentAmountInformation(current_amount);
    this.view.$emit('nextStep');
  }
}
