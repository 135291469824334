






































































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import EditRetirementFundYourCurrentAmountViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-retirement-fund-goal-plan/edit-retirement-fund-your-current-amount-view-model';

@Component({
  name: 'EditRetirementFundYourCurrentAmount',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class EditRetirementFundYourCurrentAmount extends Vue {
  edit_current_amount_view_model = Vue.observable(
    new EditRetirementFundYourCurrentAmountViewModel(this),
  );

  mounted() {
    this.edit_current_amount_view_model.initialize();
  }
}
